html {
    overflow-y: hidden;

}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
      background-color: grey;
    box-shadow: inset 0 0 3px black; 
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a5788; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #262d47; 
  }

body {
background-color: #0b0d14;
/* opacity: .8; */
color: #9db2bd;
height: 100%;
min-height: 100%;
margin: 0;
/* position: relative; */
}

/* body::after {
    content: "";
    background: url('./assets/background1.webp');
    background-size: contain;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -100;   
  } */

 @media screen and (max-width: 800px) {
     body {
         background-size: unset;
     }
 }